<template>
	<ValidationObserver v-slot="{ valid, invalid, validated, passes, validate, handleSubmit, errors }" ref="observer">
		<div class="fill-height pa-5 pa-sm-10" v-if="AppStore.started_app" id="comment-page">
			<v-container class="fill-height d-flex align-center flex-row">
				<v-row class="">
					<v-card elevation="0" class="mx-auto pa-1 pa-sm-10 mb-5" rounded="xl">

						<v-col cols="12" class="text-h2 pt-5 pb-7 text-center" id="comment-title"
						       v-html="AppStore.data.configuration[AppStore.locale].comment_title">
						</v-col>

						<v-col cols="12 text-center" v-if="AppStore.data.configuration[AppStore.locale].comment_help">
							<div id="comment-help" v-html="AppStore.data.configuration[AppStore.locale].comment_help"
							     :style="'color:'+AppStore.data.general.secondary_color.hexa"></div>
						</v-col>

						<v-col cols="12" v-if="AppStore.data.configuration.display_mic_btn">
							<section>
								<div id="mic-btn" :class="{ active: isRecording}" @click="toggleMic"
								     :style="isRecording ? 'background-color:'+AppStore.data.general.primary_color.hexa : ''">
									<span
										v-html="isRecording ?  AppStore.data.configuration[AppStore.locale].mic_btn_text_stop : AppStore.data.configuration[AppStore.locale].mic_btn_text"
										style="font-size: 35px;"></span>
									<span style="--i:0;"></span>
									<span style="--i:1;"></span>
									<span style="--i:2;"></span>
									<span style="--i:3;"></span>

								</div>
							</section>
						</v-col>

						<v-col cols="12" class="">
							<input
								type="text"
								v-autowidth="{maxWidth: '960px', minWidth: '20px', comfortZone: 0}"
								class="openKeyboardBtn text-button mb-5"
								@click="$refs.comment.focus(); outlinedTextArea = true"
								:style="'border-color:'+AppStore.data.general.primary_color.hexa+';background-color:'+AppStore.data.general.primary_color.hexa"
								:placeholder="AppStore.data.configuration[AppStore.locale].comment_keyboard_btn_text"
								v-if="AppStore.data.configuration[AppStore.locale].comment_keyboard_btn_text && !outlinedTextArea && !AppStore.data.configuration.display_mic_btn"
							/>

							<ValidationProvider name="comment" :rules="commentRules" v-slot="{ errors }">
								<v-textarea
									v-model="AppStore.rating.comment"
									:error-messages="errors"
									:placeholder="_.includes([0,1,2], AppStore.rating.rate) ? AppStore.data.configuration[AppStore.locale].comment_negative_placeholder : AppStore.data.configuration[AppStore.locale].comment_positive_placeholder"
									persistent-placeholder
									outlined
									@focus="outlinedTextArea = true; commentFieldFocus = true"
									ref="comment"
									id="comment-textarea"
									rows="9"
									auto-grow
									@input="getCommentRule()"
									persistent-hint
									class="mb-3"
									name="comment">
								</v-textarea>
							</ValidationProvider>

						</v-col>
						<v-col cols="12" id="comment-questions"
						       v-if="questions_to_display && questions_to_display.length">
							<div v-for="(question, questionIndex) in questions_to_display"
							     :key="questionIndex+questionKey" class="comment-question">
								<divider-view v-if="question.format === 'divider'"></divider-view>
								<slider-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'slider'"
									:rules="question.is_required ? { regex: /^[1-5]+$/ } : ''"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></slider-view>
								<text-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'text'"
									:rules="question.is_required ? 'required' : ''"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></text-view>
								<textarea-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'textarea'"
									:rules="question.is_required ? 'required' : ''"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></textarea-view>
								<select-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'select'"
									:rules="question.is_required ? 'required' : ''"
									:name="questions_to_display[questionIndex].name"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></select-view>
								<checkbox-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'checkbox'"
									:rules="question.is_required ? 'required' : ''"
									:color="AppStore.data.general.primary_color"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></checkbox-view>
								<radio-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'radio'"
									:rules="question.is_required ? 'required' : ''"
									:color="AppStore.data.general.primary_color"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></radio-view>
								<switch-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'switch'"
									:rules="question.is_required ? 'required' : ''"
									:color="AppStore.data.general.primary_color"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></switch-view>
							</div>
						</v-col>

						<v-col cols="12" class="text-center">
							<!--							<v-btn-->
							<!--								v-if="AppStore.rating.comment.trim().length < AppStore.data.configuration.comment_chars_required_number"-->
							<!--								@click="passes(submit)" rounded-->
							<!--								outlined elevation="0"-->
							<!--								:large="AppStore.data.general.button_size === 'large'"-->
							<!--								:x-large="AppStore.data.general.button_size === 'x-large'"-->
							<!--								:x-small="AppStore.data.general.button_size === 'x-small'"-->
							<!--								:small="AppStore.data.general.button_size === 'small'"-->
							<!--								:color="AppStore.data.general.primary_color.hexa" class="revert-btn text-button"-->
							<!--								:style="Helpers.getButtonSize(AppStore.data.general.button_size)"-->
							<!--								v-html="AppStore.data.configuration[AppStore.locale].comment_retry_btn_text">-->
							<!--							</v-btn>-->
							<v-btn
								@click="passes(submit)" rounded elevation="0" dark
								:large="AppStore.data.general.button_size === 'large'"
								:x-large="AppStore.data.general.button_size === 'x-large'"
								:x-small="AppStore.data.general.button_size === 'x-small'"
								:small="AppStore.data.general.button_size === 'small'"
								@keypress.enter="navigate"
								class="text-button"
								:color="AppStore.data.general.primary_color.hexa"
								:style="Helpers.getButtonSize(AppStore.data.general.button_size)"
								v-html="AppStore.data.configuration[AppStore.locale].comment_validate_btn_text">
							</v-btn>
						</v-col>
					</v-card>
				</v-row>
			</v-container>
		</div>
	</ValidationObserver>

</template>

<script>
import VTextAreaWithValidation from "../../../components/communs/inputs/VTextAreaWithValidation.vue";
import AppStore from "../../../stores/AppStore";
import _ from 'lodash';
import VueInputAutowidth from 'vue-input-autowidth'
import Vue from "vue";
import Stepper from "../../../components/communs/Stepper.vue";
import {EventBus} from "../../../services/Request";
import Helpers from "../../../services/Helpers";
import TextareaView from "../../../components/communs/inputViewer3/TextareaView.vue";
import TextView from "../../../components/communs/inputViewer3/TextView.vue";
import SliderView from "../../../components/communs/inputViewer3/SliderView.vue";
import SwitchView from "../../../components/communs/inputViewer3/SwitchView.vue";
import DividerView from "../../../components/communs/inputViewer3/DividerView.vue";
import CheckboxView from "../../../components/communs/inputViewer3/CheckboxView.vue";
import RadioView from "../../../components/communs/inputViewer3/RadioView.vue";
import SelectView from "../../../components/communs/inputViewer3/SelectView.vue";
import {ValidationObserver, ValidationProvider} from "vee-validate";

Vue.use(VueInputAutowidth)
export default {
	name: 'comment-view',

	components: {
		ValidationObserver, ValidationProvider,
		SelectView, RadioView, CheckboxView, DividerView, SwitchView, SliderView, TextView, TextareaView,
		VTextAreaWithValidation,
		Stepper
	},

	props: {},

	data: () => ({
		AppStore,
		commentRules: '',
		outlinedTextArea: false,
		questions: [],
		questions_to_display: [],
		commentFieldFocus: false,

		micTimeout: 0,
		isRecording: false,
		recognition: null,
		questionKey:0
	}),

	created() {
	},

	async mounted() {
		EventBus.$on('refreshQuestions', (data) => {
			this.questionKey = this.questionKey+1
		});
		this.questions = []
		this.questions_to_display = []
		await this.getBookmarkedQuestions()
		if (AppStore.questions_by_page[3] && AppStore.questions_by_page[3].length > AppStore.data.configuration.questions_to_display_3) {
			await this.getRandomQuestions();
		} else {
			this.questions = Object.assign([], _.filter(AppStore.questions_by_page[3], (q) => {
				return !q.is_bookmarked
			}))
			this.questions_to_display = this.questions_to_display.concat(this.questions)
		}
	},

	computed: {
		Helpers() {
			return Helpers
		},
		_() {
			return _;
		}
	},

	watch: {},

	methods: {
		toggleMic() {
			if (this.isRecording) {
				this.stopRecording();
			} else {
				this.startRecording();
			}
		},
		startRecording() {
			this.isRecording = true;

			const hasComment = AppStore.rating.comment.length
			const oldComment = AppStore.rating.comment
			// console.log('Speech recognition started');

			if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
				this.recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
				this.recognition.continuous = true;
				this.recognition.interimResults = true;

				this.recognition.onstart = () => {
					// console.log('Speech recognition started');
				};

				this.recognition.onend = () => {
					// console.log('Speech recognition ended');
					this.stopRecording()
				};

				this.recognition.onresult = (event) => {
					clearTimeout(this.micTimeout)

					const results = event.results;
					const newTranscript = Array.from(results).map((result) => result[0].transcript).join('');
					if(hasComment) {
						AppStore.rating.comment = oldComment + ' ' + newTranscript.charAt(0).toUpperCase() + newTranscript.slice(1);
					} else {
						AppStore.rating.comment = newTranscript;
					}
					if (results[0].isFinal) {
						// console.log('Final result:', newTranscript);
							this.micTimeout = setTimeout(() => {
								this.stopRecording()
							}, 3000);


						AppStore.rating.comment = AppStore.rating.comment.charAt(0).toUpperCase() + AppStore.rating.comment.slice(1) +'.'
					} else {
						// console.log('Interim result:', newTranscript);
					}

					// AppStore.rating.comment = AppStore.rating.comment.length && results[0].isFinal ? AppStore.rating.comment + ' '+ newTranscript : newTranscript;

				};

				this.recognition.start();
			} else {
				console.log('Speech recognition not supported');
			}
		},
		stopRecording() {
			this.isRecording = false;
			// console.log('Speech recognition stopped');
			if (this.recognition) {
				this.recognition.stop();
			}
		},
		getBookmarkedQuestions() {
			this.questions_to_display = Object.assign([], AppStore.bookmarkedQuestions[3])
		},

		getRandomQuestions() {
			const max = AppStore.data.configuration.questions_to_display_3 - this.questions_to_display.length
			this.questions = Object.assign([], _.filter(AppStore.questions_by_page[3], (q) => {
				return !q.is_bookmarked
			}))

			for (let i = 0; i < max; i++) {
				const randomQuestionIndex = Math.floor(Math.random() * (this.questions.length));
				this.questions_to_display.push(this.questions[randomQuestionIndex]);
				this.questions.splice(randomQuestionIndex, 1)
			}
		},
		submit() {
			this.commentFieldFocus = false
			if (AppStore.data.configuration.skip_questions_page && !AppStore.data.configuration.skip_timeline_page) {
				EventBus.$emit('storeRating', 'noReset')
				this.$router.push({name: 'tb-3-timeline'})
			} else if (AppStore.data.configuration.skip_questions_page && AppStore.data.configuration.skip_timeline_page) {
				EventBus.$emit('storeRating', 'noReset')
				this.$router.push({name: 'tb-3-home'})
			} else {
				this.$router.push({name: 'tb-3-questions'})
			}
		},

		getCommentRule() {
			if (AppStore.rating.comment.length > 0 && AppStore.data.configuration.comment_chars_required_number > 0) {
				this.commentRules = 'required|min:' + AppStore.data.configuration.comment_chars_required_number
			} else {
				this.commentRules = ''
			}
		}
	}
};
</script>
<style scoped lang="scss">
.openKeyboardBtn {
	margin: 0 auto;
	display: block;
	min-width: 64px;
	height: 44px;
	padding: 0 16px;
	background-color: rgb(252, 119, 83);
	border-color: rgb(252, 119, 83);
	font-size: 1.2rem;
	color: white !important;
	border-radius: 28px;
	caret-color: transparent;
	font-weight: 700;
	letter-spacing: 0.0892857143em;
	justify-content: center;
	outline: 0;
	position: relative;
	text-decoration: none;
	text-indent: 0.0892857143em;
	text-transform: uppercase;
	transition-duration: 0.28s;
	transition-property: box-shadow, transform, opacity;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;
}

.openKeyboardBtn::placeholder {
	color: white !important;
}

//Mic
section {
	display: flex;
	justify-content: center;
	align-items: center;
	//min-height: 70px;
	//animation: colorAnimate 8s linear infinite;
}

//@keyframes colorAnimate {
//	100% {
//		filter: hue-rotate(360deg)
//	}
//}

#mic-btn {
	position: relative;
	width: 70px;
	height: 70px;
	border-radius: 50%;
}

#mic-btn span:first-child {
	position: absolute;
	width: 70px;
	height: 70px;
	text-align: center;
	background: inherit;
	border-radius: inherit;
	z-index: 1000;
	align-content: center;
}

.active span:first-child {

}

.active span:not(:first-child) {
	position: absolute;
	width: 70px;
	height: 70px;
	background: inherit;
	border-radius: inherit;
	opacity: .8;
	animation: pulseAnimate 4s ease-out infinite;
	animation-delay: calc(1s * var(--i));
}

@keyframes pulseAnimate {
	100% {
		opacity: 0;
		transform: scale(1.5);
	}
}
</style>
